<template>
  <v-container class="invoices pb-8 fadeInFast">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>

    <HeadlineTableView
        :icon="'mdi mdi-file-document'"
        :headline="'Rechnungen'"
        :button-icon="'mdi mdi-file-document'"
        :entity="'invoice'"
        @buttonClick="openDialogCreateInvoice()"
        @button2Click="openDialogCreateQuote()"

    ></HeadlineTableView>

    <v-card flat class="rounded-xl pa-2 pa-sm-4">
      <v-row>
        <v-col cols="12" md="8">
          <v-container>
            <v-row class="text-center">
              <v-col cols="12" md="4">
                <h5>
                  Unbezahlte Rechnungen
                </h5>
                <h4>{{ openInvoice }}</h4>
              </v-col>

              <v-col cols="12" md="4">
                <h5>
                  Unbezahlte Rechnungen
                </h5>
                <h4>
                  {{ replaceDot(openSum) }}€
                </h4>
              </v-col>
              <v-col cols="12" md="4">
                <h5>
                  Offene Angebote
                </h5>
                <h4>{{ openInvoiceQ }}</h4>
              </v-col>
            </v-row>
          </v-container>

          <Tab
              :tabs="['Rechnungen','Angebote','Archiv (R)', 'Archiv (A)']"
              :register="register"
              @changed="register=$event"
          >
          </Tab>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field type="text" v-model="searchText" label="Suche Rechnung..."
                        class="me-6 ps-6 pe-6 pt-2 pb-2 searchInput" @keyup="search"/>
        </v-col>
      </v-row>

      <div v-if="loaded">
        <TableView
            v-if="register === 0"
            :headLines="headLines"
            :rows="invoicesSearched"
            :archiveEnabled="true"
            :exportEnabled="false"

            :textNoResults="'Hier werden deine Rechnungen angezeigt.'"
            :entity="'invoice'"
            @archiveEntries="archiveInvoices"
            @deleteEntries="deleteInvoices"
            @openDetail="openDetailInvoice"
        ></TableView>
        <TableView
            v-if="register === 1"
            :headLines="headLines"
            :rows="quotesSearched"
            :archiveEnabled="true"
            :exportEnabled="false"
            :textNoResults="'Hier werden deine Angebote angezeigt.'"
            :entity="'invoice'"
            @archiveEntries="archiveInvoices"
            @deleteEntries="deleteInvoices"
            @openDetail="openDetailInvoice"
        ></TableView>
        <TableView
            v-if="register === 2"
            :headLines="headLines"
            :rows="invoicesSearchedArchived"
            :textNoResults="'Hier werden deine archivierten Rechnungen angezeigt.'"
            :entity="'invoice'"
            :exportEnabled="false"
            @deleteEntries="deleteInvoices"
            @openDetail="openDetailInvoice"
        ></TableView>

        <TableView
            v-if="register === 3"
            :headLines="headLines"
            :rows="quotesSearchedArchived"
            :exportEnabled="false"
            :textNoResults="'Hier werden deine archivierten Angebote angezeigt.'"
            :entity="'invoice'"
            @deleteEntries="deleteInvoices"
            @openDetail="openDetailInvoice"
        ></TableView>
      </div>
      <Spinner v-else></Spinner>
      <CreateInvoice
          :dialog="dialog"
          :openAfterCreate="true"
          @hideDialog="hideDialog"
      ></CreateInvoice>
      <CreateQuote
          :dialog="dialogQuote"
          :openAfterCreate="true"
          @hideDialog="hideDialog"
      ></CreateQuote>
    </v-card>
  </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {dotToKomma, error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const TableView = () => import("@/components/tableView/TableView");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const CreateInvoice = () => import("@/components/dialog/createDialogs/CreateInvoice");
const CreateQuote = () => import("@/components/dialog/createDialogs/CreateQuote");

const Spinner = () => import("@/components/generalUI/Spinner");
const Tab = () => import("@/components/generalUI/Tab");
const delay = ms => new Promise(res => setTimeout(res, ms));


export default {
  name: 'Invoices',
  components: {
    TableView,
    Message,
    HeadlineTableView,
    CreateInvoice,
    CreateQuote,
    Spinner,
    Tab
  },
  computed: {
    ...mapGetters('invoice', {
      invoices: 'invoices',
      invoicesSearched: 'invoicesSearched',
      invoicesSearchedArchived: 'invoicesSearchedArchived',
      invoicesArchived: 'invoicesArchived',
      openInvoice: 'openInvoice',
      openSum: 'openSum',
      quotes: 'quotes',
      quotesSearched: 'quotesSearched',
      quotesSearchedArchived: 'quotesSearchedArchived',
      quotesArchived: 'quotesArchived',
      openInvoiceQ: 'openInvoiceQ',
      openSumQ: 'openSumQ',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
  data() {
    return {
      dialog: false,
      dialogQuote: false,
      loaded: false,
      register: 0,
      message: msgObj(),
      searchText: '',
      headLines: [
        {text: "Betreff", sort: 'subject'},
        {text: "Nummer", sort: 'invoiceNumber'},
        {text: "Datum", sort: 'invoiceDate'},
        {text: "Status", sort: 'status'},
        {text: "Preis", sort: 'price'},
      ]
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.loaded = true

      this.$store.dispatch('invoice/getInvoices', {
        uid: res
      }).catch((err) => {
        this.message = error(err)
      })
      this.$store.dispatch('settings/getStatusForSelection', {
        uid: this.user.id
      }).catch((err) => {
        this.message = error(err)
      })
    })
  },
  methods: {
    openDialogCreateInvoice() {
      this.dialog = true
    },
    openDialogCreateQuote() {
      this.dialogQuote = true
    },
    replaceDot(float) {
      return dotToKomma(float)
    },
    openDetailInvoice(id) {
      this.$router.push("rechnungen/rechnung/" + id)
    },
    hideDialog() {
      this.dialog = false
      this.dialogQuote = false
    },
    search() {
      if (this.register === 0) {
        this.$store.dispatch('sort/search', {search: this.searchText, list: this.invoices}
        ).then((results) => {
          this.$store.dispatch('invoice/setInvoicesSearched', results)
        })
      } else if (this.register === 1) {
        this.$store.dispatch('sort/search', {search: this.searchText, list: this.invoicesArchived}
        ).then((results) => {
          this.$store.dispatch('invoice/setInvoicesSearchedArchived', results)
        })
      }
    },
    async deleteInvoices(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('invoice/deleteInvoice', {
          uid: this.user.id,
          invoiceId: id,
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.loaded = true
            if (ids.length === 1) {
              this.message = success("Eine Rechnung erfolgreich gelöscht.")
            } else {
              this.message = success(ids.length + " Rechnungen erfolgreich gelöscht.")
            }
          }
        })
        await delay(100)
        i++
      }
    },
    async archiveInvoices(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('customer/editField', {
          id,
          uid: this.user.id,
          row: 'isArchived',
          newValue: true,
          entity: 'invoice'
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.loaded = true
            this.$store.dispatch('invoice/getInvoices', {
              uid: this.user.id
            }).then(() => {
              if (ids.length === 1) {
                this.message = success("Rechnung erfolgreich archiviert.")
              } else {
                this.message = success(ids.length + " Rechnungen erfolgreich archiviert.")
              }
            }).catch((err) => {
              this.message = error(err)
            })
          }
        })
        await delay(100)
        i++
      }
    },
  }
}
</script>
<style>


</style>
